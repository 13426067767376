import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Navbar from "@components/navbar"
import Footer from "@components/footer"
import Announcement from "@components/announcement"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="page-layout">
      <section className="intro intro--no-waves">
        <div className="container">
          <Navbar />
          <div className="container-contact container text-left">
            <h1 className="labs-title">
              Contact Us
              <span className="labs-blue">.</span>
            </h1>
          </div>
        </div>
      </section>
      <div className="intro-waves"></div>
      <section className="contact-page wave-wrapper">
        <Footer />
      </section>
    </div>
  </Layout>
)

export default ContactPage
